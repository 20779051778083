import React, { useState,useEffect } from 'react';
import {DataCatalogueContext} from './globalContext';
import catlogJson from './json/catalogdata.json'
 
export default function CatalogProvider(props) {
  const [cpiCount, setCpiCount] = useState(null);
  const [iipCount, setIIPCount] = useState(null);
  const [nasCount, setNasCount] = useState(null);
  const [plfsCount, setPlfsCount] = useState(null);
  const [asiCount, setAsiCount] = useState(null);
  const [hcesCount, setHcesCount] = useState(null);
  const [misCount, setMisCount] = useState(null);
  const [allCount, setAllCount] = useState(null);
  //console.log(catlogJson)

  const filterData =()=>{
    let filtercpi= catlogJson.filter((item)=>item.Product=='CPI');
    let filteriip= catlogJson.filter((item)=>item.Product=='IIP');
    let filternas= catlogJson.filter((item)=>item.Product=='NAS');
    let filterplfs= catlogJson.filter((item)=>item.Product=='PLFS');
    let filterasi= catlogJson.filter((item)=>item.Product=='ASI');
    let filterhces= catlogJson.filter((item)=>item.Product=='HCES');
    let filtermis= catlogJson.filter((item)=>item.Product=='MIS');
    let filterall= catlogJson.filter((item)=>item.Product);

    setCpiCount(filtercpi.length);
    setIIPCount(filteriip.length);
    setNasCount(filternas.length);
    setPlfsCount(filterplfs.length);
    setAsiCount(filterasi.length);
    setHcesCount(filterhces.length);
    setMisCount(filtermis.length);
    setAllCount(filterall.length);
  }
 
  useEffect(() => {
    filterData();
  }, []);

  return (
      <DataCatalogueContext.Provider value={{ cpiCount, iipCount, nasCount, plfsCount, asiCount, hcesCount, misCount, allCount }}>
        {props.children} 
      </DataCatalogueContext.Provider>
  );
};
